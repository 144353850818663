<template>
  <v-form
    ref="form"
    v-model="valid"
    @submit.prevent="onSubmit"
  >
    <v-card>
      <v-card-title>
        <slot name="form-title">
          <span class="font-weight-semibold text-base text--primary">Ajouter un nouvel auteur</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="$emit('update:is-form-active',false)"
          >
            <v-icon size="22">
              {{ icons.mdiClose }}
            </v-icon>
          </v-btn>
        </slot>
      </v-card-title>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <v-autocomplete
                v-model="item.author_id"
                :rules="[validators.required]"
                :error-messages="errorMessages.title"
                :label="$t('Auteur')"
                item-text="full_name"
                item-value="id"
                outlined
                dense
                hide-details="auto"
                :items="authors"
                :loading="isLoadingAuthors"
                :search-input.sync="search"
                clearable
              >
                <template #item="{ item }">
                  <AvatarName :options="{avatar: item.avatar, label: item.full_name }" />
                </template>
                <template #selection="{ item }">
                  <AvatarName :options="{avatar: item.avatar, label: item.full_name }" />
                </template>
                <template #no-data>
                  <span>{{ $t("L'auteur.") }} <strong>{{ search }}</strong> {{ $t("n'existe pas.") }}</span>
                </template>
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="12"
              md="12"
            >
              <!--              <v-text-field
                v-model="item.address"
                outlined
                dense
                :rules="[validators.required]"
                :error-messages="errorMessages.address"
                :label="$t('Adresse')"
                :placeholder="$t('Adresse')"
                hide-details="auto"
              ></v-text-field>-->
              <PlacePicker
                v-model="item.address"
                outlined
                dense
                :rules="[validators.required]"
                :error-messages="errorMessages.address"
                :label="$t('Adresse')"
                :placeholder="$t('Adresse')"
                hide-details="auto"
                @changed="addressChanged"
              />
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-combobox
                v-model="item.address2"
                outlined
                dense
                :error-messages="errorMessages.address"
                :label="$t('Adresse 2')"
                :placeholder="$t('Adresse 2')"
                hide-details="auto"
                :items="item.events"
              ></v-combobox>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="item.phone"
                outlined
                dense
                :error-messages="errorMessages.phone"
                :label="$t('Téléphone')"
                :placeholder="$t('Téléphone')"
                hide-details="auto"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-autocomplete
                v-model="item.country_id"
                :label="$t('Pays')"
                :rules="[validators.required]"
                :error-messages="errorMessages.country_id"
                :items="$store.state['app'].pays"
                item-text="name"
                item-value="id"
                outlined
                dense
                hide-details="auto"
                clearable
              >
              </v-autocomplete>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-combobox
                v-model="item.city"
                outlined
                dense
                :rules="[validators.required]"
                :error-messages="errorMessages.city"
                :label="$t('Ville')"
                :placeholder="$t('Ville')"
                hide-details="auto"
                :items="item.events"
              ></v-combobox>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-combobox
                v-model="item.department"
                outlined
                dense
                :error-messages="errorMessages.department"
                :label="$t('Departement')"
                :placeholder="$t('Departement')"
                hide-details="auto"
                :items="item.events"
              ></v-combobox>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-combobox
                v-model="item.postal_code"
                outlined
                dense
                :error-messages="errorMessages.postal_code"
                :label="$t('Code postal')"
                :placeholder="$t('Code postal')"
                hide-details="auto"
                :items="item.events"
              ></v-combobox>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="item.latitude"
                outlined
                dense
                :error-messages="errorMessages.latitude"
                :label="$t('Latitude')"
                :placeholder="$t('Latitude')"
                hide-details="auto"
                type="number"
              ></v-text-field>
            </v-col>
            <v-col
              cols="12"
              sm="6"
            >
              <v-text-field
                v-model="item.longitude"
                outlined
                dense
                :error-messages="errorMessages.longitude"
                :label="$t('Longitude')"
                :placeholder="$t('Longitude')"
                hide-details="auto"
                type="number"
              ></v-text-field>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <slot name="form-actions">
          <slot name="form-cancel">
            <v-btn
              :loading="loading"
              color="secondary"
              outlined
              type="reset"
              @click="resetItem"
            >
              {{ $t('Annuler') }}
            </v-btn>
          </slot>
          <v-spacer />
          <v-btn
            :loading="loading"
            color="primary"
            class="me-3"
            type="submit"
          >
            {{ $t("Enregistrer") }}
          </v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-form>
</template>

<script>
import { mdiClose, mdiPencilOutline, mdiPencilOffOutline } from '@mdi/js'
import { computed, ref, watch } from '@vue/composition-api'
import { required, emailValidator } from '@core/utils/validation'
import store from '@/store'
import controller from './Controller'
import AvatarName from '@/components/AvatarName'
import PlacePicker from '@/components/PlacePicker.vue'

export default {
  components: { PlacePicker, AvatarName },
  model: {
    prop: 'object',
    event: 'update:is-form-active',
  },
  props: {
    object: {
      type: Object,
      default: () => {
      },
    },
    isFormActive: {
      type: Boolean,
      required: true,
    },
    withPassword: {
      type: Boolean,
      default: true,
    },
    roleOptions: {
      type: Array,
      default: () => [],
    },
    planOptions: {
      type: Array,
      default: () => [],
    },
  },
  setup(props, { emit }) {
    const {
      form,
      isSnackbarBottomVisible,
      snackBarMessage,
    } = controller()
    const errorMessages = ref({})
    const valid = ref(false)
    const loading = ref(false)
    const isLoadingCountries = ref(false)
    const validate = () => {
      form.value.validate()
    }

    const authors = ref([])
    const isLoadingAuthors = ref(false)
    const search = ref(null)

    const item = computed({
      get: () => props.object,
      set: value => emit('update:object', value),
    })

    const searchAuthors = term => {
      store.dispatch('app/searchAuthors', { term, rowsPerPage: 100 }).then(response => {
        isLoadingAuthors.value = false
        for (let i = 0; i < response.data.data.length; i++) {
          const u = response.data.data[i]
          const indexU = authors.value.findIndex(el => el.id === u.id)
          if (indexU < 0) {
            authors.value.push(u)
          }
        }
      })
    }

    const addressChanged = value => {
      console.log('baba', value)
      item.value.events = [value.display_name, value.name, ...value.display_name.split(',')]
      item.value.latitude = parseFloat(value.lat)
      item.value.longitude = parseFloat(value.lon)
      item.value.address = value.display_name
    }

    if (item.value.author && authors.value.findIndex(ele => ele.id === item.value.author_id) < 0) {
      authors.value.push(item.value.author)
    }
    watch(item, val => {
      if (item.value.author && authors.value.findIndex(ele => ele.id === item.author_id) < 0) {
        authors.value.push(item.value.author)
      }
    }, { deep: true })

    watch(search, val => {
      if (!val || val.trim().length < 3 || isLoadingAuthors.value) return ''
      isLoadingAuthors.value = true
      searchAuthors(val)

      return ''
    })

    // const item = ref(JSON.parse(JSON.stringify(blankItem)))
    const resetItem = () => {
      // item.value = {}

      // emit('update:object', item.value)
    }

    const onSubmit = () => {
      if (valid.value) {
        loading.value = true
        store.dispatch('event/addItem', item.value)
          .then(response => {
            loading.value = false
            if (response.data.error || response.data.errors || (response.data.status && response.data.status === 'false')) {
              if (response.data.errors) {
                // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
                errorMessages.value = { ...response.data.errors }
              } else {
                isSnackbarBottomVisible.value = true
                snackBarMessage.value = response.data.message

                // this.toast(response.data.message, this.$vuetify.theme.currentTheme.error)
              }

              return false
            }
            resetItem()

            // emit('update:is-form-active', false)

            emit('refetch-data')
          }).catch(error => {
            loading.value = false
            console.log('error', error)
            if (error.data.errors) {
            // this.toast(this.$t('These credentials do not match our records'), this.$vuetify.theme.currentTheme.error)
              errorMessages.value = { ...error.data.errors }
            }
          })
      } else {
        validate()
      }
    }
    // eslint-disable-next-line no-unused-vars
    /* watch(() => props.object, (newValue, prevValue) => {
      if (!newValue || !newValue.id) {
        resetItem()
      } else {
        item.value = JSON.parse(JSON.stringify(newValue))
      }
    }) */
    // eslint-disable-next-line no-unused-vars
    /* watch(() => item, (newValue, prevValue) => {
      console.log('item change', newValue.value)
      emit('input', newValue.value)
    }, { deep: true }) */

    return {
      addressChanged,
      resetItem,
      form,
      search,
      authors,
      isLoadingAuthors,
      isLoadingCountries,
      isSnackbarBottomVisible,
      snackBarMessage,
      errorMessages,
      onSubmit,
      item,
      valid,
      loading,
      validate,

      // validation
      validators: { required, emailValidator },
      icons: {
        mdiClose,
        mdiPencilOffOutline,
        mdiPencilOutline,
      },
    }
  },
}
</script>
